/* we remove borders here and set foreground and background for header */
.ag-theme-alpine {
    --ag-borders: none;
    --ag-row-border-style: none;
    --ag-header-foreground-color: white;
    --ag-header-background-color: #636fdd;
}

/* we set border radius for header row  */
.ag-theme-alpine .ag-header {
    border-radius: 8px;
}

/* we set background and border radius for odd rows */
.ag-theme-alpine .ag-row-odd {
    background-color: rgb(238, 241, 238);
    border-radius: 8px;
}

/* we set background and border radius for even rows */
.ag-theme-alpine .ag-row-even {
    background-color: white;
    border-radius: 8px;
}