.checkbox-container {
    position: absolute;
    top: -5px;
    /* Distance from the top of the image */
    left: -2px;
    /* Distance from the left of the image */
    z-index: 10;
    /* Make sure the checkbox is above the image */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    /* Increase the clickable area */
    height: 45px;
    /* Increase the clickable area */
}

/* Hide the default checkbox */
.custom-checkbox {
    display: none;
}

/* Create custom checkbox style using :before */
.custom-label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-checkbox+.custom-label:before {
    width: 17px;
    /* Keep the visual size of the checkbox */
    height: 17px;
    /* Keep the visual size of the checkbox */
    border: 2px solid transparent;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    background: linear-gradient(#FFFFFF, #FFFFFF), linear-gradient(157.4deg, #FF77C0, #8745F3, #70D4FF);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    display: inline-block;
    margin-right: 1ex;
    content: "";
    position: relative;
}

/* Style the checkbox when checked with gradient background and no border */
.custom-checkbox:checked+.custom-label:before {
    background: linear-gradient(157.4deg, #FF77C0 7.03%, #8745F3 48.3%, #70D4FF 95.95%);
    border: none;
    content: "\2714";
    font-size: 16px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}